/**
 * This component displays the formation choices for an exchange application
 */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import LeftArrow from '../../../../assets/images/junia-icon-fleche-3.png';
import './Exchange.scss';
import { Application_hed } from '../../../../services/api/Candidature/interface';
import { Contact, me } from '../../../../services/api/Contact/interface';
import ContactService from '../../../../services/api/Contact/ContactService';
import _ from 'lodash';
import applicationPicklist from '../../../../assets/data/hed_application.json';
import { checkRequested } from '../../../../utils/validator/validator';

interface ContainerProps {
  previous: () => void;
  next: () => void;
  save: () => void;
  me?: me;
  applicationData: Application_hed;
  getApplication?: () => void;
  onStep: (application: Application_hed, step: number) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Exchange: React.FC<IProps> = (props) => {
  const {
    t,
    onStep,
    applicationData,
    me
  } = props;
  const { i18n } = useTranslation();
  const [height, setHeight] = useState<number>(0);
  const [contact, setContact] = useState<Contact>({});
  const divRef = useRef<HTMLDivElement>(null);
  const [canNext, setCanNext] = useState<boolean>(false);
  const [form, setForm] = useState<Application_hed>({});
  const required = [
    'p_riode_de_d_but__c',
    'dur_e_de_l_change__c',
    'programme__c',
    'specialite_principale_junia__c'
  ];

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);
  useEffect(() => {
    getContact();
    setForm({
      p_riode_de_d_but__c: applicationData.p_riode_de_d_but__c,
      dur_e_de_l_change__c: applicationData.dur_e_de_l_change__c,
      programme__c: applicationData.programme__c,
      id_heroku__c: applicationData.id_heroku__c,
      specialite_principale_junia__c: applicationData.specialite_principale_junia__c
    });
    let canNext = checkRequested(required, applicationData);
    setCanNext(canNext);
  }, [me]);

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element && element.clientHeight && element.clientHeight > 0) {
      setHeight(element.clientHeight + 8 + 70); //height + padding div + menu size
    }
  };

  const handleChange = (e: any): void => {
    let newForm = {
      ...form,
      [e.target.name]: e.target.value
    };
    let goNext = checkRequested(required, newForm);
    setCanNext(goNext);
    setForm(newForm);
  };

  const handleSubmit = (e: any) => {
    e.preventDefautl();
  };

  const getContact = async () => {
    if (me?.id_heroku__c) {
      try {
        let contactResponse = await ContactService.getContact(me?.id_heroku__c);
        setContact(contactResponse.data);
      } catch (error) {
        console.error(
          '🚀 ~ file: MissingElement.tsx ~ line 84 ~ getContact ~ error',
          error
        );
      }
    }
  };

  return (
    <div className="missing-container general-container">
      <div className="missing-elements">
        <div ref={divRef} className="missing-header">
          <button
            className="btn btn-return"
            onClick={() => onStep(applicationData, -1)}>
            <img alt="left arrow" src={LeftArrow} />
            <span>{t('return')}</span>
          </button>
          <div className="miss-header-title">
            <div className="left-element">
              <h3>{t('applyTitle')}</h3>
            </div>
            <div>
              {canNext ? (
                <button
                  className="btn btn-main"
                  onClick={() => onStep(form, +1)}>
                  <span>{t('next')}</span>
                </button>
              ) : (
                <button className="btn btn-disabled">
                  <span>{t('next')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div
          className="missing-list-container"
          style={{ height: 'calc(98% - ' + height + 'px)' }}>
          <div className="missing-list">
            <form onSubmit={handleSubmit}>
              <div className="custom-checkbox">
                <h6 className="form-h6">
                  {t('desiredCampus')} <span className="form-required">*</span>
                </h6>
                <div className="custom-checkbox">
                  <input
                    type="radio"
                    className="custom-check"
                    id="hei"
                    name="programme__c"
                    value="HEI"
                    checked={form.programme__c === 'HEI'}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="hei"
                    className={form.programme__c === 'HEI' ? 'selected' : ''}>
                    HEI
                  </label>
                  <input
                    type="radio"
                    className="custom-check"
                    id="isa"
                    name="programme__c"
                    value="ISA"
                    checked={form.programme__c === 'ISA'}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="isa"
                    className={form.programme__c === 'ISA' ? 'selected' : ''}>
                    ISA
                  </label>
                  <input
                    type="radio"
                    className="custom-check"
                    id="isen"
                    name="programme__c"
                    value="ISEN"
                    checked={form.programme__c === 'ISEN'}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="isen"
                    className={form.programme__c === 'ISEN' ? 'selected' : ''}>
                    ISEN
                  </label>
                  <input
                    type="radio"
                    className="custom-check"
                    id="junia"
                    name="programme__c"
                    value="JUNIA"
                    checked={form.programme__c === 'JUNIA'}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="junia"
                    className={form.programme__c === 'JUNIA' ? 'selected' : ''}>
                    JUNIA
                  </label>
                </div>
              </div>
              <div>
                <h6 className="form-h6">
                  {t('juniaSpecialty')} <span className="form-required">*</span>
                </h6>
                <select
                  name="specialite_principale_junia__c"
                  value={form.specialite_principale_junia__c}
                  onBlur={handleChange}
                  onChange={handleChange}>
                  <option value=""></option>
                  {_.chain(
                    applicationPicklist.picklistFieldValues[
                      'Specialite_principale_Junia__c'
                    ]
                  )
                    .value()
                    .values.map((res) => {
                      if (form.programme__c) {
                        let programmeId;
                        if (form.programme__c === 'HEI') {
                          programmeId = 0;
                        } else if (form.programme__c === 'ISA') {
                          programmeId = 1;
                        } else if (form.programme__c === 'ISEN') {
                          programmeId = 2;
                        } else if (form.programme__c === 'JUNIA') {
                          programmeId = 3;
                        }
                        return res.validFor.includes(Number(programmeId))
                          ? res
                          : null;
                      } 
                      return null;
                    })
                    .filter((res: any) => {
                      return res;
                    })
                    .map((specialite) => {
                      if (i18n.language === 'fr') {
                        return (
                          <option
                            key={specialite?.value}
                            value={specialite?.value}>
                            {specialite?.label.replace(/&#39;/g, "'")}
                          </option>
                        );
                      }
                      if (i18n.language === 'en') {
                        return (
                          <option
                            key={specialite?.value}
                            value={specialite?.value}>
                            {specialite?.value}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <div>
                <h6 className="form-h6">
                  {t('instructionLanguage')}{' '}
                  <span className="form-required">*</span>
                </h6>
                <select
                  name="langue_d_enseignement__c"
                  value={form.langue_d_enseignement__c}
                  onBlur={handleChange}
                  onChange={handleChange}>
                  <option value={''}></option>
                  {applicationPicklist.picklistFieldValues[
                    'Langue_d_enseignement__c'
                  ].values.map((value) => {
                    if (i18n.language === 'fr') {
                      return (
                        <option key={value.value} value={value.value}>
                          {value.label}
                        </option>
                      );
                    }
                    if (i18n.language === 'en') {
                      return (
                        <option key={value.value} value={value.value}>
                          {value.value}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
              <div>
                <h6 className="form-h6">
                  {t('desiredDuration')}{' '}
                  <span className="form-required">*</span>
                </h6>
                <select
                  name="dur_e_de_l_change__c"
                  value={form.dur_e_de_l_change__c}
                  onBlur={handleChange}
                  onChange={handleChange}>
                  <option value={''}></option>
                  {applicationPicklist.picklistFieldValues[
                    'Dur_e_de_l_change__c'
                  ].values.map((value) => {
                    if (i18n.language === 'fr') {
                      return (
                        <option key={value.value} value={value.value}>
                          {value.label}
                        </option>
                      );
                    }
                    if (i18n.language === 'en') {
                      return (
                        <option key={value.value} value={value.value}>
                          {value.value}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
              <div>
                <h6 className="form-h6">
                  {t('startPeriod')} <span className="form-required">*</span>
                </h6>
                <select
                  name="p_riode_de_d_but__c"
                  value={form.p_riode_de_d_but__c}
                  onBlur={handleChange}
                  onChange={handleChange}>
                  <option value={''}></option>
                  {applicationPicklist.picklistFieldValues[
                    'P_riode_de_d_but__c'
                  ].values.map((value) => {
                    if (i18n.language === 'fr') {
                      return (
                        <option key={value.value} value={value.value}>
                          {value.label}
                        </option>
                      );
                    }
                    if (i18n.language === 'en') {
                      return (
                        <option key={value.value} value={value.value}>
                          {value.value}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['home', 'form', 'common'])(Exchange)
);
